/*
  Theme Name: Reveal
  Theme URL: https://bootstrapmade.com/reveal-bootstrap-corporate-template/
  Author: BootstrapMade.com
  License: https://bootstrapmade.com/license/
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    background: #fff;
    color: #444;
    font-family: "Open Sans", sans-serif;
}

a {
    color: #50d8af;
    transition: 0.5s;
}

a:hover, a:active, a:focus {
    color: #51d8af;
    outline: none;
    text-decoration: none;
}

p {
    padding: 0;
    margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
    padding: 0;
}

/* Back to top button */
.back-to-top {
    position: fixed;
    display: none;
    background: #50d8af;
    color: #fff;
    padding: 6px 12px 9px 12px;
    font-size: 16px;
    border-radius: 2px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
}

@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
    }
}

.back-to-top:focus {
    background: #50d8af;
    color: #fff;
    outline: none;
}

.back-to-top:hover {
    background: #0c2e8a;
    color: #fff;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

#topbar .contact-info a {
    line-height: 1;
    color: #555;
}

#topbar .contact-info a:hover {
    color: #50d8af;
}

#topbar .contact-info i {
    color: #50d8af;
    padding: 4px;
}

#topbar .contact-info .fa-phone {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #e9e9e9;
}

#topbar .social-links a {
    color: #555;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    border-left: 1px solid #e9e9e9;
}

#topbar .social-links a:hover {
    color: #50d8af;
}

#topbar .social-links a:first-child {
    border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    padding: 20px 0;
    height: 84px;
    transition: all 0.5s;
    z-index: 997;
    background: #fff;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

#header #logo h1 {
    font-size: 42px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

#header #logo h1 a {
    color: #0c2e8a;
    line-height: 1;
    display: inline-block;
}

#header #logo h1 a span {
    color: #50d8af;
}

#header #logo img {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    #header {
        padding: 20px 0;
        height: 74px;
    }

    #header #logo h1 {
        font-size: 34px;
    }

    #header #logo img {
        max-height: 40px;
    }
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
#intro {
    width: 100%;
    height: 60vh;
    position: relative;
    background: url("../img/intro-carousel/1.jpg") no-repeat;
    background-size: cover;
}

#intro .intro-content {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#intro .intro-content h2 {
    color: #0c2e8a;
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: 700;
}

#intro .intro-content h2 span {
    color: #50d8af;
    text-decoration: underline;
}

@media (max-width: 767px) {
    #intro .intro-content h2 {
        font-size: 34px;
    }
}

#intro .intro-content .btn-get-started, #intro .intro-content .btn-projects {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 32px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
}

#intro .intro-content .btn-get-started {
    background: #0c2e8a;
    border: 2px solid #0c2e8a;
}

#intro .intro-content .btn-get-started:hover {
    background: none;
    color: #0c2e8a;
}

#intro .intro-content .btn-projects {
    background: #50d8af;
    border: 2px solid #50d8af;
}

#intro .intro-content .btn-projects:hover {
    background: none;
    color: #50d8af;
}

#intro #intro-carousel {
    z-index: 8;
}

#intro #intro-carousel::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 7;
}

#intro #intro-carousel .item {
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition-property: opacity;
    transition-property: opacity;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
}

.nav-menu li {
    position: relative;
    white-space: nowrap;
}

.nav-menu > li {
    float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
    display: block;
}

.nav-menu ul ul {
    top: 0;
    left: 100%;
}

.nav-menu ul li {
    min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
    padding-right: 22px;
}

.sf-arrows .sf-with-ul:after {
    content: "\f107";
    position: absolute;
    right: 8px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
    content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
    float: right;
    margin: 0;
}

@media (max-width: 768px) {
    #nav-menu-container {
        display: none;
    }
}

/* Nav Meu Styling */
.nav-menu a {
    padding: 10px 8px;
    text-decoration: none;
    display: inline-block;
    color: #555;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 14px;
    outline: none;
}

.nav-menu li:hover > a, .nav-menu > .menu-active > a {
    color: #50d8af;
}

.nav-menu > li {
    margin-left: 10px;
}

.nav-menu ul {
    margin: 4px 0 0 0;
    padding: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
}

.nav-menu ul li {
    transition: 0.3s;
}

.nav-menu ul li a {
    padding: 10px;
    color: #333;
    transition: 0.3s;
    display: block;
    font-size: 13px;
    text-transform: none;
}

.nav-menu ul li:hover > a {
    color: #50d8af;
}

.nav-menu ul ul {
    margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    margin: 20px 20px 0 0;
    border: 0;
    background: none;
    font-size: 24px;
    display: none;
    transition: all 0.4s;
    outline: none;
    cursor: pointer;
}

#mobile-nav-toggle i {
    color: #555;
}

@media (max-width: 768px) {
    #mobile-nav-toggle {
        display: inline;
    }
}

/* Mobile Nav Styling */
#mobile-nav {
    position: fixed;
    top: 0;
    padding-top: 18px;
    bottom: 0;
    z-index: 998;
    background: rgba(52, 59, 64, 0.9);
    left: -260px;
    width: 260px;
    overflow-y: auto;
    transition: 0.4s;
}

#mobile-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#mobile-nav ul li {
    position: relative;
}

#mobile-nav ul li a {
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    padding: 10px 22px 10px 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    outline: none;
}

#mobile-nav ul li a:hover {
    color: #fff;
}

#mobile-nav ul li li {
    padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
    position: absolute;
    right: 0;
    z-index: 99;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
    color: #50d8af;
}

#mobile-nav ul .menu-item-active {
    color: #50d8af;
}

#mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(52, 59, 64, 0.9);
    display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
    overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
    left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
    color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Header
--------------------------------*/
.section-header {
    margin-bottom: 30px;
}

.section-header h2 {
    font-size: 32px;
    color: #0c2e8a;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
}

.section-header h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #50d8af;
    bottom: 0;
    left: 0;
}

.section-header p {
    padding: 0;
    margin: 0;
}

/* About Section
--------------------------------*/
#about {
    padding: 60px 0 30px 0;
}

#about .about-img {
    overflow: hidden;
}

#about .about-img img {
    margin-left: -15px;
    max-width: 100%;
}

@media (max-width: 768px) {
    #about .about-img {
        height: auto;
    }

    #about .about-img img {
        margin-left: 0;
        padding-bottom: 30px;
    }
}

#about .content h2 {
    color: #0c2e8a;
    font-weight: 700;
    font-size: 36px;
    font-family: "Raleway", sans-serif;
}

#about .content h3 {
    color: #555;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    font-style: italic;
}

#about .content p {
    line-height: 26px;
}

#about .content p:last-child {
    margin-bottom: 0;
}

#about .content i {
    font-size: 20px;
    padding-right: 4px;
    color: #50d8af;
}

#about .content ul {
    list-style: none;
    padding: 0;
}

#about .content ul li {
    padding-bottom: 10px;
}

/* Services Section
--------------------------------*/
#services {
    padding: 30px 0 0 0;
}

#services .box {
    padding: 40px;
    margin-bottom: 40px;
    box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
    background: #fff;
    transition: 0.4s;
}

#services .box:hover {
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
}

#services .box .icon {
    float: left;
}

#services .box .icon i {
    color: #444;
    font-size: 64px;
    transition: 0.5s;
    line-height: 0;
    margin-top: 34px;
}

#services .box .icon i:before {
    background: #0c2e8a;
    background: linear-gradient(45deg, #50d8af 0%, #a3ebd5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#services .box h4 {
    margin-left: 100px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
}

#services .box h4 a {
    color: #444;
}

#services .box p {
    font-size: 14px;
    margin-left: 100px;
    margin-bottom: 0;
    line-height: 24px;
}

@media (max-width: 767px) {
    #services .box .box {
        margin-bottom: 20px;
    }

    #services .box .icon {
        float: none;
        text-align: center;
        padding-bottom: 15px;
    }

    #services .box h4, #services .box p {
        margin-left: 0;
        text-align: center;
    }
}

/* Clients Section
--------------------------------*/
#clients {
    padding: 30px 0;
}

#clients img {
    max-width: 100%;
    opacity: 0.5;
    transition: 0.3s;
    padding: 15px 0;
}

#clients img:hover {
    opacity: 1;
}

#clients .owl-nav, #clients .owl-dots {
    margin-top: 5px;
    text-align: center;
}

#clients .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
}

#clients .owl-dot.active {
    background-color: #50d8af;
}

/* Our Portfolio Section
--------------------------------*/
#portfolio {
    background: #fff;
    padding: 30px 0;
}

#portfolio .portfolio-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
}

#portfolio .portfolio-item {
    overflow: hidden;
    position: relative;
    padding: 0;
    vertical-align: middle;
    text-align: center;
}

#portfolio .portfolio-item h2 {
    color: #ffffff;
    font-size: 24px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 700;
}

#portfolio .portfolio-item img {
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
    width: 100%;
}

#portfolio .portfolio-item:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

#portfolio .portfolio-item:hover .portfolio-overlay {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
}

#portfolio .portfolio-info {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* Testimonials Section
--------------------------------*/
#testimonials {
    padding: 30px 0;
}

#testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 0 30px;
    margin: 30px 15px;
    text-align: center;
    min-height: 350px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

@media (max-width: 767px) {
    #testimonials .testimonial-item {
        margin: 30px 10px;
    }
}

#testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
}

#testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

#testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

#testimonials .testimonial-item .quote-sign-left {
    margin-top: -15px;
    padding-right: 10px;
    display: inline-block;
    width: 37px;
}

#testimonials .testimonial-item .quote-sign-right {
    margin-bottom: -15px;
    padding-left: 10px;
    display: inline-block;
    max-width: 100%;
    width: 37px;
}

#testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

#testimonials .owl-nav, #testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
}

#testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
}

#testimonials .owl-dot.active {
    background-color: #50d8af;
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
    background: #081e5b;
    background-size: cover;
    padding: 40px 0;
}

#call-to-action .cta-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

#call-to-action .cta-text {
    color: #fff;
}

@media (min-width: 769px) {
    #call-to-action .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

#call-to-action .cta-btn {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 26px;
    border-radius: 3px;
    transition: 0.5s;
    margin: 10px;
    border: 3px solid #fff;
    color: #fff;
}

#call-to-action .cta-btn:hover {
    background: #50d8af;
    border: 3px solid #50d8af;
}

/* Our Team Section
--------------------------------*/
#team {
    background: #fff;
    padding: 30px 0 0 0;
}

#team .member {
    text-align: center;
    margin-bottom: 20px;
}

#team .member .pic {
    overflow: hidden;
    text-align: center;
}

#team .member .pic img {
    max-width: 100%;
}

#team .member .details {
    background: #50d8af;
    color: #fff;
    padding: 15px;
    border-radius: 0 0 3px 3px;
}

#team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
}

#team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

#team .member .social {
    margin-top: 5px;
}

#team .member .social a {
    color: #fff;
}

#team .member .social i {
    font-size: 16px;
    margin: 0 2px;
}

/* Contact Section
--------------------------------*/
#contact {
    padding: 30px 0;
}

#contact .contact-info {
    margin-bottom: 20px;
    text-align: center;
}

#contact .contact-info i {
    font-size: 48px;
    display: inline-block;
    margin-bottom: 10px;
    color: #50d8af;
}

#contact .contact-info address, #contact .contact-info p {
    margin-bottom: 0;
    color: #000;
}

#contact .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #999;
}

#contact .contact-info a {
    color: #000;
}

#contact .contact-info a:hover {
    color: #50d8af;
}

#contact .contact-address, #contact .contact-phone, #contact .contact-email {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    #contact .contact-address, #contact .contact-phone, #contact .contact-email {
        padding: 20px 0;
    }
}

@media (min-width: 768px) {
    #contact .contact-phone {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

#contact #google-map {
    height: 290px;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    #contact #google-map {
        margin-top: 20px;
    }
}

#contact .form #sendmessage {
    color: #50d8af;
    border: 1px solid #50d8af;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#contact .form #errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
    display: block;
}

#contact .form .validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

#contact .form input, #contact .form textarea {
    padding: 10px 14px;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
}

button span {
    margin-bottom: 2px;
}

#contact .form button[type="submit"] {
    background: #50d8af;
    border: 0;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
}

#contact .form button[type="submit"]:hover {
    background: #2dc899;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #f2f5f8;
    padding: 0 0 30px 0;
    font-size: 14px;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    text-align: center;
    font-size: 13px;
    color: #555;
}

#footer .credits a {
    color: #0c2e8a;
}
